import React, {Component} from "react";

class L14564 extends Component {
    render() {
        var l14564Style = {
            backgroundColor:    "transparent",
            border:             "1px solid black",/*
            display:            "flex",*/
            flex:               "1",
            padding:            "3px",
            margin:             "3px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={l14564Style}>
                <h3>Lei nº 14.564, de 4 de maio de 2023.</h3>
                <p>Altera a Lei nº 12.715, de 17 de setembro de 2012, para prorrogar a faculdade de dedução do imposto sobre a renda dos valores correspondentes a doações e patrocínios em prol de ações e serviços do Programa Nacional de Apoio à Atenção Oncológica (Pronon) e do Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência (Pronas/PCD).</p>
            </div>
        );
    }
}

export default L14564;