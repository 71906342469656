import React, {Component} from "react";

exports.myValue3 = 2;
exports.myValue4 = 3;
exports.myValue5 = 4;

let PRODUCTS = [
    {id:0,qtd:0,categoria:"Toalhas Linha Premium",descricao:"",image:"images/prod-0001.jpg",tamanho:"0,80 x 1,60",peso:"742",preco:80.64,tot:0},
    {id:1,qtd:0,categoria:"Toalhas Linha Premium",descricao:"",image:"images/prod-0001.jpg",tamanho:"0,70 x 1,50",peso:"504",preco:58.48,tot:0},
    {id:2,qtd:0,categoria:"Toalhas Linha Premium",descricao:"",image:"images/prod-0001.jpg",tamanho:"0,80 x 1,60",peso:"440",preco:54.01,tot:0},
    {id:3,qtd:0,categoria:"Toalhas Linha Premium",descricao:"",image:"images/prod-0001.jpg",tamanho:"0,80 x 1,60",peso:"168",preco:23.48,tot:0},
    {id:4,qtd:0,categoria:"Toalhas Linha Gold",descricao:"",image:"images/prod-0002.jpg",tamanho:"0,70 x 1,50",peso:"462",preco:49.14,tot:0},
    {id:5,qtd:0,categoria:"Toalhas Linha Gold",descricao:"",image:"images/prod-0002.jpg",tamanho:"0,50 x 0,80",peso:"176",preco:18.66,tot:0},
    {id:6,qtd:0,categoria:"Toalhas Linha Classic",descricao:"",image:"images/prod-0003.jpg",tamanho:"0,70 x 1,40",peso:"420",preco:44.20,tot:0},
    {id:7,qtd:0,categoria:"Toalhas Linha Classic",descricao:"",image:"images/prod-0003.jpg",tamanho:"0,45 x 0,70",peso:"135",preco:16.79,tot:0},
    {id:8,qtd:0,categoria:"Toalhas Linha Pratic",descricao:"",image:"images/prod-0004.jpeg",tamanho:"0,70 x 1,40",peso:"320",preco:33.06,tot:0},
    {id:9,qtd:0,categoria:"Toalhas Piso",descricao:"",image:"images/prod-0005.jpg",tamanho:"0,48 x 0,70",peso:"160",preco:20.87,tot:0},
    {id:10,qtd:0,categoria:"Toalhas Piso",descricao:"",image:"images/prod-0005.jpg",tamanho:"0,50 x 0,80",peso:"200",preco:25.59,tot:0},
    {id:11,qtd:0,categoria:"Toalhas Banho Piscina",descricao:"",image:"images/prod-0006.jpeg",tamanho:"0,75 x 1,35",peso:"446",preco:54.25,tot:0},
    {id:12,qtd:0,categoria:"Toalhas Banho Piscina",descricao:"",image:"images/prod-0006.jpeg",tamanho:"0,70 x 1,40",peso:"412",preco:48.19,tot:0},
];

class Tamanho extends React.Component {
    render() {
        var itemStyle = {
            backgroundColor:    "lightgreen",
            border:             "2px solid green",
            display:            "flex",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            fontFamily:         "sans-serif",
        };
        return (
            <div style={itemStyle}>
                <p>Tamanho: {this.props.tamanho}</p>
            </div>
        );
    }
}  
class Peso extends React.Component {
    render() {
        var itemStyle = {
            backgroundColor:    "lightblue",
            border:             "2px solid green",
            display:            "flex",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            fontFamily:         "sans-serif",
        };
        return (
            <div style={itemStyle}>
                <p>{this.props.peso} Gr</p>
            </div>
        );
    }
}  
class Preco extends React.Component {
    render() {
        var itemStyle = {
            backgroundColor:    "lightgreen",
            border:             "2px solid green",
            display:            "flex",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            fontFamily:         "sans-serif",                    
        };
        return (
            <div style={itemStyle}>
                <p>Preço: {this.props.preco}</p>
            </div>
        );
    }
}
class Categoria extends React.Component {
    render() {
        var itemStyle = {
            backgroundColor:    "darkseagreen",
            border:             "2px solid green",
            display:            "flex",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            width:              "70%",
            fontFamily:         "sans-serif",
        };
        return (
            <div style={itemStyle}>
                <p>{this.props.categoria}</p>
            </div>
        );
    }
}  
class Adicione extends React.Component {
    constructor(props) {
        super(props);
        this.addItem=this.addItem.bind(this);
    }
    addItem(e) {       
        let i = PRODUCTS.findIndex(o => o.id === this.props.id);
        PRODUCTS[i].qtd = this._inputElement.value;
        console.log('Inside Adicione: PRODUCTS[',i,'].qtd: ',PRODUCTS[i].qtd);
        e.preventDefault();   
    }
    render() {
        var inputStyle = {
            backgroundColor:    "orange",
            border:             "2px solid green",
            display:            "flex",/*
            flex:               "1",*/
            padding:            "3px",
            margin:             "5px",
            width:              "80px",
            blockSize:          "auto",
        };
        var buttonStyle = {
            backgroundColor:    "orange",
            border:             "2px solid blue",
            display:            "inline-block",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            width:              "20px",
            blockSize:          "auto",
            justifyContent:     "right",
            width:              "90px",
        };
        return (
            <div>                       
                <form onSubmit={this.addItem}>                
                    <input style={inputStyle} type="number" min="1" id="inputTextbox"
                        ref={(a) => this._inputElement = a}></input>
                    <button type="submit" style={buttonStyle}>Adicione</button>
                </form>
            </div>
        );
    }
}
class Item extends React.Component {
    render() {
        var itemStyle = {
            backgroundColor:   "darkgoldenrod",
            display:           "flex",
            border:            "2px solid blue",
            padding:           "2px",
            margin:            "5px",
        };      
        return (
            <div style={itemStyle}>
                <Categoria categoria={this.props.categoria}></Categoria>                   
                <Tamanho   tamanho={this.props.tamanho}></Tamanho>
                <Peso      peso={this.props.peso}></Peso>
                <Preco     preco={this.props.preco}></Preco>
                <Adicione  id={this.props.id}></Adicione>                           
            </div>
        );
    }
}
class PedidoItems extends React.Component {
    constructor(props) {
        super(props);
        this.createPedidos  = this.createPedidos.bind(this);
        this.createProdutos = this.createProdutos.bind(this);
    }
    createPedidos(x) {  
        return <li key={Math.random()}>{x}</li>
    }
    createProdutos(x) {  
        return <li key={Math.random()}>{x.id}  {x.categoria}  {x.tamanho} {x.peso}  {x.preco}</li>
    }
    // From https://stackoverflow.com/questions/38648257/how-to-pass-in-a-second-argument-on-reactjs-onsubmit-function-call         
    concluir (x) {
        return event => {
            event.preventDefault();
            const PEDIDO = PRODUCTS.map (x => [x.qtd,x.id,x.categoria,x.tamanho,x.peso,x.preco,x.qtd*x.preco]);            
            for (let i=0; i<PEDIDO.length; i++) {
                if (PEDIDO[i][0] !== 0) { 
                    console.log(PEDIDO[i]);  
                }                    
            }  
        }
    }
    render() {
        var buttonStyle = {
            backgroundColor:    "orange",
            border:             "2px solid blue",
            display:            "inline-block",
            flex:               "1",
            padding:            "3px",
            margin:             "5px",
            width:              "20px",
            blockSize:          "auto",
            justifyContent:     "right",
            width:              "90px",
        };
        var produtoEntries = PRODUCTS;
        var pedidoItemsStyle = {
            backgroundColor:   "darkgoldenrod",/*}
            display:           "flex",  "Com 'flex' textAlign não funciona.*/
            textAlign:         "center",
            border:            "2px solid blue",
            padding:           "10px",
            margin:            "5px",
            fontFamily:        "sans-serif",
        };
        let listProdutos = produtoEntries.map(this.createProdutos);
        return(
            <div>
               <form onSubmit={this.concluir(listProdutos)}>
                  <button type="submit" style={buttonStyle}>Concluir</button>
               </form>
            </div>
        )   
    }
}
class Pagina extends Component {
    constructor(props) {
        super(props);
    }      
    render() {          
        var paginaStyle = {
            backgroundColor:    "darkkhaki",
        }
        const produtos = PRODUCTS.map(x => <Item key={x.id} id={x.id} categoria={x.categoria} tamanho={x.tamanho} peso={x.peso} preco={x.preco}></Item>);
        return(   
            <div style={paginaStyle}>
                {produtos}   
                <PedidoItems></PedidoItems>
            </div>
        );
    }
}
class Catalogo extends Component {
    render() {
        var catalogoStyle = {
            backgroundColor:    "darkkhaki",
            padding:            0,
            margin:             0,
        }
        return(
            <div style={catalogoStyle}>
                <Pagina></Pagina>
            </div>
        );
    }
}

export default Catalogo