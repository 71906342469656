import React, {Component} from "react";
import background from './Orofacial.jpg';
import CF1988    from "./CF1988";
import L12715    from "./L12715";
import DL7988    from "./DL7988";
import PCON5     from "./PCON5";
import L14564    from "./L14564";
import Bolha     from "./Bolha";

class Home extends Component {
    render() {
        var homeStyle = {
            backgroundColor:    "lightblue",/*
            border:             "2px solid blueviolet",
            display:            "flex",
            flex:               "1",
            padding:            "1px",*/
            margin:             "1px",
            fontFamily:         "sans-serif",/*
            width:              "661px",
            height:             "1175px",
            backgroundImage:    `url(${background})`*/
        };
        var tituloStyle = {
            textAlign: "center",
        }
        return(
            <div style={homeStyle}>
                <h2>Pronon e Pronas</h2>
                <p>Criados para incentivar a realização de projetos de atenção oncológica e de atenção à saúde da pessoa com deficiência o Programa
                Nacional de Apoio à Atenção Oncológica (Pronon) e o Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência
                (Pronas/PCD) são financiados por intermédio de leis de incentivo fiscal que permitem que pessoas físicas e jurídicas forneçam parte
                do seu imposto de renda (1% do valor devido) para programas focados em causas culturais, esportivas e de saúde, dentre outras.</p>
                <p>As instituições interessadas têm <b>até 31 de julho</b> para requerer o credenciamento e submeter projetos no próximo ano.</p>
                <p>Apoiamos as instituições para o credenciamento junto ao Ministério da Saúde, de acordo com as normas vigentes.</p>
                <h3 style={tituloStyle}>Normas Aplicáveis ao Pronon e Pronas</h3>
                <Bolha>
                <h3>Constituição Federal de 1988</h3>
                <p>Art. 195</p>
                </Bolha>
                <Bolha>
                <h3>Lei nº 12.715, de 17 de setembro de 2012.</h3>
                <p>Institui o Programa Nacional de Apoio à Atenção Oncológica e o
                Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência; e dá outras
                providências.</p>
                </Bolha>
                <Bolha>
                <h3>Decreto-Lei 7.988, de 17 de abril de 2013.</h3>
                <p>Regulamenta os arts. 1º a 13 da Lei nº 12.715, de 17 de setembro de 2012,
                que dispõem sobre o Programa Nacional de Apoio à Atenção Oncológica - PRONON e
                o Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência - PRONAS/PCD.</p>
                </Bolha>
                <Bolha>
                <h3>Portaria de Consolidação nº 5, Anexo LXXXVI, de 27 de setembro de 2017.</h3>
                <p>Define regras e critérios para o credenciamento de instituições e para apresentação, recebimento,
                análise, aprovação, execução, acompanhamento, prestação de contas e avaliação de resultados de
                projetos no âmbito do PRONON e do PRONAS/PCD.</p>
                </Bolha>
                <Bolha>
                <h3>Lei nº 14.564, de 4 de maio de 2023.</h3>
                <p>Altera a Lei nº 12.715/2012, para prorrogar a faculdade de dedução do imposto sobre a renda dos valores correspondentes a doações e patrocínios em prol de ações e serviços do PRONON e do PRONAS/PCD.</p>
                </Bolha>
            </div>
            /*
            <div style={homeStyle}>
                <h2>Pronon e Pronas/PCD prorrogados até 2026</h2>
                <p>O Programa Nacional de Apoio à Atenção Oncológica (Pronon) e o Programa Nacional de Apoio à Atenção da Pessoa com Deficiência (Pronas/PCD) do Ministério da Saúde foram criados para apoiar instituições sem fins lucrativos que atuam nessas áreas.</p>
                <p>As instituições interessadas têm <b>até 31 de julho</b> para requerer o credenciamento e submeter projetos no próximo ano.</p>
                <p>Nossa missão é ajudar as instituições para o credenciamento junto ao Ministério da Saúde, de acordo com as normas vigentes (Lei 12.715/2012, Decreto 7.988/2013 e Lei 14.564/2023).</p>
            </div>
            */
        );
    }
}

export default Home;