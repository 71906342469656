import React, {Component} from "react";

class Stuff extends Component {
    render() {
        var stuffStyle = {
            backgroundColor:    "lightblue",/*
            border:             "2px solid blueviolet",
            display:            "flex",*/
            flex:               "1",
            padding:            "1px",
            margin:             "1px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={stuffStyle}>
                <h2>AeD Arquitetura, Construção e Consultoria.</h2>
                <p>Somos uma equipe multiprofissional e interdisciplinar com objetivo de apoiar Instituições sem Fins
                Lucrativos desde o credenciamento, passando pela elaboração de projetos, captação de recursos, acompanhamento da execução
                até a prestação de contas aprovada, viabilizando maior efetividade e eficácia dos recursos financeiros e humanos aplicados.</p>
                <h3>Responsável Técnica e Operacional</h3>
                <p><b>Valmerísia Nogueira Rodrigues</b></p>
                <p>Arquiteta e urbanista, atuou no Ministério da Saúde em análise, avaliação e monitoramento de Políticas Públicas em Sáude, projetos e obras.
                </p>
                <p>Possui ampla experiência na elaboração e acompanhamento de projetos hospitalares e obras em diversas regiões do país, no âmbito do SUS. perfazendo mais de 30 anos de carreira profissional.</p>
                <p>Especialista em planejamento, gestão financeira e orçamentária, acompanhamento e avaliação de recursos públicos, além de pareceres técnicos, elaboração e revisão de atos normativos de gestão pública.  </p>
            </div>
        );
    }
}

export default Stuff;