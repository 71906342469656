import React, {Component} from "react";

class Contact extends Component {    
    render() {
        var contactStyle = {
            backgroundColor:    "lightblue",/*
            border:             "2px solid blueviolet",
            display:            "flex",*/
            flex:               "1",
            padding:            "1px",
            margin:             "1px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={contactStyle}>
                <h2>Dúvidas?</h2>
                <p>Entre em contato agora: <b>(61) 9-8155-2856</b> (WhatsApp).
                <p>valmerisia@yahoo.com.br</p>
                <p>instagram.com/valmerisia_arquitetaeurb/</p>
                </p>
            </div>
        );
    }
}

export default Contact;