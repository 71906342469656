import React, {Component} from "react";

class DL7988 extends Component {
    render() {
        var dl7988Style = {
            backgroundColor:    "transparent",
            border:             "1px solid black",/*
            display:            "flex",*/
            flex:               "1",
            padding:            "3px",
            margin:             "3px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={dl7988Style}>
                <h3>Decreto-Lei 7.988, de 17 de abril de 2013.</h3>
                <p>Regulamenta os arts. 1º a 13 da Lei nº 12.715, de 17 de setembro de 2012,
                que dispõem sobre o Programa Nacional de Apoio à Atenção Oncológica - PRONON e
                o Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência - PRONAS/PCD.</p>
            </div>
        );
    }
}

export default DL7988;