import React, {Component} from "react";
import {Route, Routes, NavLink, HashRouter} from "react-router-dom";
import Home     from "./Home";
import Catalogo from "./Catalogo";
import Compras  from "./Compras";
import Contact  from "./Contact";
import Stuff    from "./Stuff";
import Visao    from "./Visao";
import About    from "./About";

const b = require("./b.js");
console.log("Main.js: myValue ",b.myValue);
console.log("Main.js: myValue1",b.myValue1);
console.log("Main.js: myValue2",b.myValue2);

const c = require("./Catalogo.js");
console.log("Main.js: myValue3",c.myValue3);
console.log("Main.js: myValue4",c.myValue4);
console.log("Main.js: myValue5",c.myValue5);

class Main extends Component {
    render() {
        let tituloStyle = {
            color:     "black",/* */
            textAlign: "center",
        }
        return(
            <HashRouter>
                <div>
                    <h1 style={tituloStyle}>AeD Assessoria - Pronon e Pronas/PCD</h1>
                    <ul className="header">
                        {/*Exact param will no longer working on NavLink component. In version 6 Beta they have included a new param called: end*/}
                        <li><NavLink end to="/">Pronon/Pronas</NavLink></li>               
                        <li><NavLink to="/sobre">Quem Somos</NavLink></li>
                        <li><NavLink to="/visao">Missão e Valores</NavLink></li>{/*
                        <li><NavLink to="/about">Sobre</NavLink></li>*/}
                        <li><NavLink to="/contato">Contato</NavLink></li>         
                    </ul>
                    <div className="content">
                        <Routes>
                            <Route path="/"         element={<Home></Home>}></Route>                           
                            <Route path="/sobre"    element={<Stuff></Stuff>}></Route>
                            <Route path="/visao"    element={<Visao></Visao>}></Route>{/*}
                            <Route path="/about"    element={<About></About>}></Route>*/}
                            <Route path="/contato"  element={<Contact></Contact>}></Route> 
                        </Routes>
                    </div>
                </div>
            </HashRouter>
        );
    }
}

export default Main;