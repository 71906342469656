import React, {Component} from "react";
import CF1988    from "./CF1988";
import L12715    from "./L12715";
import DL7988    from "./DL7988";
import PCON5     from "./PCON5";
import L14564    from "./L14564";

class About extends Component {
    render() {
        return(
            <div></div>            
        );
    }
}

export default About;