import React, {Component} from "react";

class Bolha extends Component {
    render() {
        var bolhaStyle = {
            backgroundColor:    "#ffc26f",
            border:             "1px solid black",
            display:            "flex",
            flex:               "1",
            padding:            "30px",
            margin:             "3px",            
            fontFamily:         "sans-serif",
            display:            "inline-block",
            borderRadius:       "25%",
            width:              170,
            height:             350,
            verticalAlign:      "middle",
            fontSize:           "14px",
        };
        return(
            <div style={bolhaStyle}>
                {this.props.children}
            </div>
        );
    }
}

export default Bolha;