import React, {Component} from "react";

class PCON5 extends Component {
    render() {
        var pconsStyle = {
            backgroundColor:    "transparent",
            border:             "1px solid black",/*
            display:            "flex",*/
            flex:               "1",
            padding:            "3px",
            margin:             "3px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={pconsStyle}>
                <h3>Portaria de Consolidação nº 5, Anexo LXXXVI, de 27 de setembro de 2017.</h3>
                <p>Das regras e critérios para o credenciamento de instituições e para apresentação, recebimento,
                análise, aprovação, execução, acompanhamento, prestação de contas e avaliação de resultados de
                projetos no âmbito do Programa Nacional de Apoio à Atenção Oncológica (PRONON) e do Programa
                Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência (PRONAS/PCD).</p>
            </div>
        );
    }
}

export default PCON5;