import React, {Component} from "react";

class CF1988 extends Component {
    render() {
        var cf1988Style = {
            backgroundColor:    "transparent",
            border:             "1px solid black",/*
            display:            "flex",*/
            flex:               "1",
            padding:            "3px",
            margin:             "3px",            
            fontFamily:         "sans-serif",/*
            display:            "inline-block",
            borderRadius:       "50%",
            width:              "20%",
            hight:              200,*/
        };
        return(
            <div style={cf1988Style}>
                <h3>Constituição Federal de 1988</h3>
                <p>Art. 195</p>
            </div>
        );
    }
}

export default CF1988;