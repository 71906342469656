import React, {Component} from "react";

class L12715 extends Component {
    render() {
        var l12715Style = {
            backgroundColor:    "transparent",
            border:             "1px solid black",/*
            display:            "flex",*/
            flex:               "1",
            padding:            "3px",
            margin:             "3px",            
            fontFamily:         "sans-serif",/*
            display:            "inline-block",
            borderRadius:       "50%",
            width:              "20%",
            hight:              200,*/
        };
        return(
            <div style={l12715Style}>
                <h3>Lei nº 12.715, de 17 de setembro de 2012.</h3>
                <p>Institui o Programa Nacional de Apoio à Atenção Oncológica e o
                Programa Nacional de Apoio à Atenção da Saúde da Pessoa com Deficiência; e dá outras
                providências.</p>
            </div>
        );
    }
}

export default L12715;