import React, {Component} from "react";

class Visao extends Component {
    render() {
        var visaoStyle = {
            backgroundColor:    "lightblue",/*
            border:             "2px solid blueviolet",
            display:            "flex",*/
            flex:               "1",
            padding:            "1px",
            margin:             "1px",
            fontFamily:         "sans-serif",
        };
        return(
            <div style={visaoStyle}>
                <h2>Missão</h2>
                <p>Apoiar Instituições sem Fins Lucrativos na elaboração de projetos sociais exequíveis em conformidade com legislação pertinente e que beneficie
                com efetividade a sociedade demandante.</p>
                <h2>Visão</h2>
                <p>Transversalizar conhecimento na elaboração de projetos sociais para uma assistência que defenda o valor da vida.</p>
            </div>
        );
    }
}

export default Visao;